import React, { ReactElement } from 'react'
import '../../styles/hexagonGrid.css'

import coworking from '../../images/branchen-icons/icn-coworking.svg'
import genossenschaften from '../../images/branchen-icons/icn-genossenschaften.svg'
import logistik from '../../images/branchen-icons/icn-logistik.svg'
import market from '../../images/branchen-icons/icn-marketplace.svg'
import tourismus from '../../images/branchen-icons/icn-tourismus.svg'
import verein from '../../images/branchen-icons/icn-verein.svg'
import versicherung from '../../images/branchen-icons/icn-versicherung.svg'
import { SectionText } from '../Section'

export default function HexagonGrid(): ReactElement {
  return (
    <section id="sectors">
      <div className="content">
        <SectionText
          // containerClass="pt-14"
          title="Für Kunden mit wiederkehrenden Zahlungen"
          text="payactive arbeitet mit Kunden aus verschiedensten Branchen zusammen. Besonders gut passen wir zu Unternehmen mit wiederkehrenden Zahlungen wie Lastschriften oder Überweisungen. Übrigens: Spezifische Lösungen entwickeln wir mit unseren Kunden gerne zusammen. Sprich uns einfach an."
          headlineVariant="underline"
        />
      </div>

      <div className="hexgrid">
        <div className="hexrow">
          <div className="hex op0">
            <div className="hex-content">1</div>
          </div>
          <div className="hex op0">
            <div className="hex-content">2</div>
          </div>
          <div className="hex op0">
            <div className="hex-content">3</div>
          </div>
          <div className="hex op1">
            <div className="hex-content">4</div>
          </div>
          <div className="hex op1">
            <div className="hex-content">5</div>
          </div>
          <div className="hex op1">
            <div className="hex-content">6</div>
          </div>
          <div className="hex op0">
            <div className="hex-content">7</div>
          </div>
          <div className="hex op0">
            <div className="hex-content">8</div>
          </div>
          <div className="hex op0">
            <div className="hex-content">9</div>
          </div>
        </div>
        <div className="hexrow">
          <div className="hex op0">
            <div className="hex-content">10</div>
          </div>
          <div className="hex op1">
            <div className="hex-content">11</div>
          </div>
          <div className="hex op1">
            <div className="hex-content">12</div>
          </div>
          <div className="hex op4">
            <div className="hex-content">13</div>
          </div>
          <div className="hex op1">
            <div className="hex-content">14</div>
          </div>
          <div className="hex op4">
            <div className="hex-content">15</div>
          </div>
          <div className="hex op1">
            <div className="hex-content">16</div>
          </div>
          <div className="hex op1">
            <div className="hex-content">17</div>
          </div>
          <div className="hex op0">
            <div className="hex-content">18</div>
          </div>
        </div>
        <div className="hexrow">
          <div className="hex op1">
            <div className="hex-content">19</div>
          </div>
          <div className="hex op4">
            <div className="hex-content">20</div>
          </div>
          <div className="hex op4">
            <div className="hex-content">21</div>
          </div>
          <div
            className="hex sector"
            aria-hidden="true"
            onKeyDown={() =>
              document.getElementById('branches-after')?.scrollIntoView({
                behavior: 'smooth',
              })
            }
            onClick={() =>
              document.getElementById('branches-after')?.scrollIntoView({
                behavior: 'smooth',
              })
            }
          >
            <div className="hex-content">
              <img id="verein-img" src={verein} alt="verein" />
              <h4 className="font-medium font-serif leading-tight" id="verein">
                Verein
              </h4>
            </div>
          </div>
          <div className="hex op4">
            <div className="hex-content">23</div>
          </div>
          <div
            className="hex sector"
            aria-hidden="true"
            onKeyDown={() =>
              document.getElementById('branches-after')?.scrollIntoView({
                behavior: 'smooth',
              })
            }
            onClick={() =>
              document.getElementById('branches-after')?.scrollIntoView({
                behavior: 'smooth',
              })
            }
          >
            <div className="hex-content w-full">
              <img id="logistik-img" src={logistik} alt="logistik" />
              <h4 className="font-medium font-serif" id="logistik">
                Logistik
              </h4>
            </div>
          </div>
          <div className="hex op4">
            <div className="hex-content">25</div>
          </div>
          <div className="hex op4">
            <div className="hex-content">26</div>
          </div>
          <div className="hex op1">
            <div className="hex-content">27</div>
          </div>
        </div>
        <div className="hexrow">
          <div className="hex op1">
            <div className="hex-content">28</div>
          </div>
          <div className="hex op4">
            <div className="hex-content">29</div>
          </div>
          <div
            className="hex sector"
            aria-hidden="true"
            onKeyDown={() =>
              document.getElementById('branches-after')?.scrollIntoView({
                behavior: 'smooth',
              })
            }
            onClick={() =>
              document.getElementById('branches-after')?.scrollIntoView({
                behavior: 'smooth',
              })
            }
          >
            <div className="hex-content">
              <img
                id="versicherung-img"
                src={versicherung}
                alt="versicherung"
              />
              <h4 className="font-medium font-serif" id="versicherung">
                Versicherungen
              </h4>
            </div>
          </div>
          <div
            className="hex sector"
            aria-hidden="true"
            onKeyDown={() =>
              document.getElementById('branches-after')?.scrollIntoView({
                behavior: 'smooth',
              })
            }
            onClick={() =>
              document.getElementById('branches-after')?.scrollIntoView({
                behavior: 'smooth',
              })
            }
          >
            <div className="hex-content">
              <img
                id="genossenschaften-img"
                src={genossenschaften}
                alt="genossenschaften"
              />
              <h4 className="font-medium font-serif" id="genossenschaften">
                Genossenschaften
              </h4>
            </div>
          </div>
          <div
            className="hex sector"
            aria-hidden="true"
            onKeyDown={() =>
              document.getElementById('branches-after')?.scrollIntoView({
                behavior: 'smooth',
              })
            }
            onClick={() =>
              document.getElementById('branches-after')?.scrollIntoView({
                behavior: 'smooth',
              })
            }
          >
            <div className="hex-content">
              <img
                id="digitale-marketplaces-img"
                src={market}
                alt="marketPlaces"
              />
              <h4
                className="font-medium font-serif leading-tight"
                id="digitale-marketplaces"
              >
                Digitale Marketplaces
              </h4>
            </div>
          </div>
          <div
            className="hex sector"
            aria-hidden="true"
            onKeyDown={() =>
              document.getElementById('branches-after')?.scrollIntoView({
                behavior: 'smooth',
              })
            }
            onClick={() =>
              document.getElementById('branches-after')?.scrollIntoView({
                behavior: 'smooth',
              })
            }
          >
            <div className="hex-content">
              <img id="tourismus-img" src={tourismus} alt="tourismus" />
              <h4 className="font-medium font-serif" id="tourismus">
                Tourismus
              </h4>
            </div>
          </div>
          <div className="hex sector sector-em">
            <div className="hex-content">
              <h4 className="font-medium font-serif" id="deineBranche">
                Deine Branche
              </h4>
            </div>
          </div>
          <div className="hex op4">
            <div className="hex-content">35</div>
          </div>
          <div className="hex op1">
            <div className="hex-content">36</div>
          </div>
        </div>
        <div className="hexrow">
          <div className="hex op1">
            <div className="hex-content">37</div>
          </div>
          <div className="hex op1">
            <div className="hex-content">38</div>
          </div>
          <div className="hex op4">
            <div className="hex-content">39</div>
          </div>
          <div className="hex op4">
            <div className="hex-content">40</div>
          </div>
          <div
            className="hex sector"
            aria-hidden="true"
            onKeyDown={() =>
              document.getElementById('branches-after')?.scrollIntoView({
                behavior: 'smooth',
              })
            }
            onClick={() =>
              document.getElementById('branches-after')?.scrollIntoView({
                behavior: 'smooth',
              })
            }
          >
            <div className="hex-content">
              <img id="co-working-spaces-img" src={coworking} alt="coworking" />
              <h4
                className="font-medium font-serif leading-tight"
                id="co-working-spaces"
              >
                Co-Working Spaces
              </h4>
            </div>
          </div>
          <div className="hex op4">
            <div className="hex-content">42</div>
          </div>
          <div className="hex op4">
            <div className="hex-content">43</div>
          </div>
          <div className="hex op1">
            <div className="hex-content">44</div>
          </div>
          <div className="hex op1">
            <div className="hex-content">45</div>
          </div>
        </div>
        <div className="hexrow">
          <div className="hex op0">
            <div className="hex-content">46</div>
          </div>
          <div className="hex op0">
            <div className="hex-content">47</div>
          </div>
          <div className="hex op1">
            <div className="hex-content">48</div>
          </div>
          <div className="hex op1">
            <div className="hex-content">49</div>
          </div>
          <div className="hex op4">
            <div className="hex-content">50</div>
          </div>
          <div className="hex op1">
            <div className="hex-content">51</div>
          </div>
          <div className="hex op1">
            <div className="hex-content">52</div>
          </div>
          <div className="hex op0">
            <div className="hex-content">53</div>
          </div>
          <div className="hex op0">
            <div className="hex-content">54</div>
          </div>
        </div>
        <div className="hexrow">
          <div className="hex op0">
            <div className="hex-content">55</div>
          </div>
          <div className="hex op0">
            <div className="hex-content">56</div>
          </div>
          <div className="hex op0">
            <div className="hex-content">57</div>
          </div>
          <div className="hex op0">
            <div className="hex-content">58</div>
          </div>
          <div className="hex op1">
            <div className="hex-content">59</div>
          </div>
          <div className="hex op0">
            <div className="hex-content">60</div>
          </div>
          <div className="hex op0">
            <div className="hex-content">61</div>
          </div>
          <div className="hex op0">
            <div className="hex-content">62</div>
          </div>
          <div className="hex op0">
            <div className="hex-content">63</div>
          </div>
        </div>
      </div>
    </section>
  )
}
