import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import Theme from '../components/theme'
import {
  SEO,
  Layout,
  SectionHero,
  SectionText,
  SectionInfo,
  SectionColumns,
  SectionRecentPosts,
  SectionBanner,
  SectionTestimonial,
  SectionLogoCloud,
} from '../components'
import { SectionNewsletter } from '../components/Section/SectionNewsletter'
import video from '../videos/jellyfish.mp4'
import paperless from '../images/paperless_all.svg'
import codeGif from '../images/code-editor-sequence.gif'
import arrowDown from '../images/ic_arrow_drop_down.svg'
import { logos } from '../data/static/logos'
import HexagonGrid from '../components/Layout/HexagonGrid'

const Index = (): React.ReactElement => {
  const {
    wpPage: {
      seo: { title, metaDesc, opengraphImage },
    },
  } = useStaticQuery(
    graphql`
      {
        wpPage(id: { eq: "cG9zdDoyMjY5" }) {
          seo {
            title
            metaDesc
            opengraphImage {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    `
  )

  return (
    <Theme>
      <SEO
        wordpressTitle={title}
        description={metaDesc}
        socialImageRelativeUrl={opengraphImage?.localFile.publicURL}
      />

      <video
        autoPlay
        muted
        loop
        className="object-cover w-screen h-screen absolute top-0 left-0 opacity-40 mix-blend-soft-light"
      >
        <source src={video} type="video/mp4" />
      </video>
      <Layout>
        <div className="relative">
          <SectionHero
            from="home"
            typewritter
            title={[
              'schnell & einfach',
              'sicher & stressfrei',
              'nachhaltig & regenerativ',
            ]}
            titleTag="h1"
            subtitle="payactive macht digitale Zahlungen"
            subtitleTag="h1"
            text="Erlebe, wie nachhaltig und effizient Payment heute sein kann."
            textTag="h3"
            actionLinks={[
              {
                type: 'scroll',
                url: '/loesungen',
                label: 'Ich will mehr wissen',
              },
            ]}
            framelessImage
          />
          <button
            className="absolute bottom-0 invisible lg:visible cursor-pointer"
            type="button"
            tabIndex={0}
            onKeyDown={() =>
              document.getElementById('services-section')?.scrollIntoView({
                behavior: 'smooth',
              })
            }
            onClick={() =>
              document.getElementById('services-section')?.scrollIntoView({
                behavior: 'smooth',
              })
            }
            id="next_page_button"
          >
            <img src={arrowDown} alt="arrow-down" className="m-auto" />
          </button>
        </div>
        <div
          className="bg-white"
          style={{
            boxShadow: '0 10px 50px rgba(0,0,0,.3)',
          }}
        >
          <SectionText
            containerId="services-section"
            headlineVariant="greenUnderline"
            title="Die smarte Zahlungsplattform für nachhaltig handelnde Unternehmen"
          />
          <SectionColumns
            from="home"
            containerClass="py-0"
            data={[
              {
                text: 'payactive vereinfacht als Payment-Plattform den Zahlungsverkehr für Unternehmen. Durch uns ermöglichte Zahlungen wirken regenerativ mit positivem Impact für unseren Planeten. Unser Fokus liegt auf den einfachsten und sichersten Zahlungsmethoden Lastschrift und Überweisung. Für die dahinter liegenden Zahlungsprozesse bieten wir flexible Module für',
              },
              {
                text: 'wiederkehrende Zahlungen, automatische Rechnungsstellung oder zur Kaufabwicklung von Produkten. So schaffen wir positive Payment-Journeys, die reibungslos funktionieren und innovative Wertschöpfungsmodelle ermöglichen. Und: Du und deine Kunden tun kostenlos etwas für unsere Welt dank payactives Social Cashback',
              },
            ]}
          />

          <SectionInfo
            containerClass="relative z-10 py-14"
            image={<img src={paperless} alt="Paperless" />}
            tabs={[
              {
                title: 'Schnell & einfach',
                text: [
                  {
                    text: 'Digitale End-to-End Rechnungslegung und Zahlung',
                  },
                  {
                    text: 'Automatisierte Payment-Module für Überweisungen und Lastschriften',
                  },
                  {
                    text: 'Komplett mobil optimierter Zahlungsprozess',
                  },
                ],
              },
              {
                title: 'Offen & sicher',
                text: [
                  {
                    text: 'Rechnungslegung, -versand und -zuordnung über Ihre API',
                  },
                  {
                    text: 'Mobile Zahlung auf Knopfdruck bei Ihren Kund:innen',
                  },
                  {
                    text: 'Voll integriert und sicher dank Open Banking Standard',
                  },
                ],
              },
              {
                title: 'Nachhaltig & regenerativ',
                text: [
                  {
                    text: 'payactive schafft Kapital für Projekte mit Impact',
                  },
                  {
                    text: 'Social Cashback wird mit jeder Zahlung an regenerative Projekte ausgeschüttet',
                  },
                  {
                    text: 'UN Nachhaltigkeitsziele Bildung, Abbau von Ungleichheit und Kampf gegen den Klimawandel im Fokus',
                  },
                ],
              },
            ]}
            actionLinks={[
              {
                label: 'Mehr zur Plattform',
                url: 'loesungen',
              },
            ]}
          />
        </div>

        <HexagonGrid />

        <div
          style={{
            backgroundColor: 'rgb(38 38 38 / 80%)',
            position: 'relative',
          }}
        >
          <SectionText
            containerClass="pt-14 pb-0"
            title="Voll integrierbar via Rest API"
            headlineVariant="underline"
          />
          <SectionInfo
            containerClass="pb-14 md:pt-14"
            title="In drei Schritten live:"
            titleTag="h3"
            from="home-api"
            image={<img src={codeGif} alt="Code sequence" />}
            actionLinks={[
              {
                variant: 'tertiary',
                label: 'Zur API Dokumentation',
                url: 'https://docs.payactive.io/apidoc',
              },
            ]}
            bullets={[
              {
                title: 'API Key Generieren',
                text: 'Melde dich im payactive Portal an und generiere dir einfach und unkompliziert deinen API Code.',
              },
              {
                title: 'API Key integrieren',
                text: 'Verwende den API Key, um Anfragen an die payactive Plattform zu authentifizieren.',
              },
              {
                title: 'Invoices senden & Payments empfangen',
                text: 'Sende per API sicher und schnell Rechnungen oder Empfange Zahlungen deiner Kunden.',
              },
            ]}
          />
        </div>

        <SectionText title="Kund:innenstimmen" containerClass="pt-14" />
        <SectionTestimonial
          testimonial={[
            {
              cite: 'Sven Baumgart | **Tremaze**',
              text: '„Innovativ, flexibel, nachhaltig: Für Tremaze bedeutet payactive den langersehnten Innovationssprung im digitalen Payment!“',
              logo: (
                <StaticImage
                  className="w-36"
                  src="../images/logo-tremaze@2x.png"
                  alt="Tremaze Logo"
                />
              ),
              image: (
                <StaticImage
                  src="../images/sven-baumgart@2x.png"
                  alt="Sven Baumgart"
                />
              ),
            },
          ]}
        />

        <SectionText
          from="home-subtitle"
          title="Weitere Unternehmen die uns bereits vertrauen:"
          containerClass="pt-14"
          centered={false}
        />
        <SectionLogoCloud data={logos} />

        <SectionBanner
          title="Gutes tun beim Geld verdienen"
          text="Wir sind überzeugt, dass wir mit payactive eine ganz außergewöhnliche Lösung für Zahlungsprozesse mit Impact geschaffen haben. Wir würden dir gerne zeigen, was wir darunter verstehen."
          subtext="Mathias Born, Co-Gründer & CEO von payactive"
          image={
            <StaticImage
              src="../images/mathiaswithstars.png"
              alt="Person mit Glitzer"
              style={{ maxWidth: 450 }}
              // className="md:w-"
            />
          }
          actionLinks={[
            {
              label: 'Termin buchen',
              url: 'termin-buchen',
            },
            {
              label: 'Per E-Mail kontaktieren',
              url: 'mailto:hello@payactive.eu?subject=Request for information',
              variant: 'link',
            },
          ]}
        />
        <SectionText title="Blog" />
        <SectionRecentPosts containerClass="pb-14" />
        <SectionNewsletter containerClass="" page="Startseite" />
      </Layout>
    </Theme>
  )
}

export default Index
